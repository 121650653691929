import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { css, useColorMode } from 'theme-ui'
import kebabCase from 'lodash/kebabCase'
import config from './../config'

const { mediaQueryKeys } = config

export default function BlogTagBlocks(props: { style?: object }) {
  const { style = {} } = props
  const {
    tags: { group = [] },
  } = useStaticQuery(
    graphql`
      query {
        tags: allMdx {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )

  return (
    <div css={css(style as any)}>
      <div
        css={css({
          ':after': {
            content: '""',
            display: 'table',
            clear: 'both',
          },
        })}
      >
        {group.map(({ fieldValue, totalCount }: { fieldValue: string; totalCount: number }) => (
          <TagBlock key={fieldValue} tag={fieldValue} count={totalCount} />
        ))}
      </div>
    </div>
  )
}

function TagBlock(props: { tag: string; count: number }) {
  const { tag = 'unknown', count = 0 } = props
  const [currentColorMode] = useColorMode()
  const isDark = currentColorMode === 'dark'
  return (
    <Link
      to={`/blog/tag/${kebabCase(tag)}`}
      css={css({
        color: 'text',
        float: 'left',
        borderRadius: count > 6 ? 2 + 'rem' : count * 0.3 + 'rem',
        px: count > 6 ? 2 + 'rem' : count * 0.3 + 'rem',
        py: count > 6 ? 2 + 'rem' : count * 0.2 + 'rem',
        border: isDark ? (theme: any) => `1px solid ${theme.colors.primary}` : 'none',
        bg: 'background',
        mx: count > 6 ? 2 + 'rem' : count * 0.3 + 'rem',
        my: count > 6 ? 2 + 'rem' : count * 0.2 + 'rem',
        cursor: 'pointer',
        boxShadow: '0.1em 0.1em 0.2em 0.2em rgba(0, 0, 0, 0.05)',
        transition: 'all 0.3s',
        textDecoration: 'none',
        [mediaQueryKeys.biggerThanMobile]: {
          ':hover': {
            transform: 'scale(1.05)',
            boxShadow: '0.1em 0.1em 0.2em 0.2em rgba(0, 0, 0, 0.1)',
          },
        },
      } as any)}
    >
      <span
        css={css({
          fontSize: [
            count > 4 ? 4 : count + 1,
            count > 5 ? 5 : count + 2,
            count > 6 ? 6 : count + 2,
          ],
          fontWeight: 'bold',
        })}
      >
        {tag}
      </span>
      <sub css={css({ fontSize: [2, 3, 4], color: 'primary', fontWeight: 'bold' })}>{count}</sub>
    </Link>
  )
}
