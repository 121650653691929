const config = {
  blogCategorys: [
    {
      label: 'something cool',
      value: 'cool',
      path: '/blog/category/cool',
      description:
        '任何足够先进的技术都与魔法无异。也许算不上魔法，只是有点有趣，有点好玩。总之，有点酷。',
    },
    {
      label: '巨人的肩膀和思想',
      value: 'great',
      path: '/blog/category/great',
      description:
        '总有人看到你所看不到的世界，思想到你不曾思想，甚至绝对思想不到的东西。那么，请带带我。',
    },
    {
      label: '书中自有',
      value: 'note',
      path: '/blog/category/note',
      description:
        '他说，“我把世上所有的一切都放在里面了，去找寻吧”。于是，拉开了大阅读时代。《海读王》',
    },
    {
      label: '一隅',
      value: 'point',
      path: '/blog/category/point',
      description:
        '我所仍远远谈不上已知的自己所看到的世界。王阳明《传习录》：人但各以其一隅之见；认定以为道止如此。',
    },
  ],
  mediaQueryKeys: {
    mobile: '@media screen and (max-width: 750px)',
    biggerThanMobile: '@media screen and (min-width: 751px)',
  },
}

export default config
