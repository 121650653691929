/** @jsx jsx */
import { jsx, css, Styled, useColorMode, Spinner } from 'theme-ui'
import { useEffect, useState } from 'react'

async function getQuote(): Promise<{ content: string; author: string } | null> {
  const res = await fetch('/api/quote')
  const data = await res.json()
  if (data && data.ok) {
    const { author, content } = data.data
    return { content, author }
  }
  return Promise.reject('error')
}

export default function (props: { style?: object }) {
  const { style = {} } = props
  const [currentColorMode] = useColorMode()
  const [quote, setQuote] = useState({ content: '', author: '' })

  const isDark = currentColorMode === 'dark'

  useEffect(() => {
    getQuote()
      .then((ret) => {
        if (!ret) {
          return
        }
        const { author, content } = ret
        setQuote({ content, author })
      })
      .catch((err) => console.error('get quote error: ' + err))
  }, [])

  function refreshQuote() {
    getQuote()
      .then((ret) => {
        if (!ret) {
          return
        }
        const { author, content } = ret
        setQuote({ content, author })
      })
      .catch((err) => console.error('get quote error: ' + err))
  }

  return (
    <div
      style={style}
      css={css({
        position: 'relative',
        width: '95%',
        maxWidth: 'blogContainerMaxWidth',
        minHeight: '6rem',
        px: '3rem',
        py: '2rem',
        mx: 'auto',
        background: !isDark
          ? 'linear-gradient(-150deg, transparent 1.5em, rgba(97, 140, 162, 0.05) 0)'
          : 'linear-gradient(-150deg, transparent 1.5em, rgba(97, 140, 162, 0.9) 0)',
        borderRadius: '1rem',
        boxShadow: '-0.2em 0.2em 0.3em -0.1em rgba(0, 0, 0, 0.25)',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '1.73rem',
          height: '3rem',
          background: !isDark
            ? `
          linear-gradient(
            to left bottom,
            transparent 50%,
            rgba(0, 0, 0, 0.2) 0,
            rgba(0, 0, 0, 0.4)
          )
          100% 0 no-repeat
          `
            : `
          linear-gradient(
            to left bottom,
            transparent 50%,
            rgba(0, 0, 0, 0.4) 0,
            rgba(0, 0, 0, 0.6)
          )
          100% 0 no-repeat
          `,
          transform: 'translateY(-1.3em) rotate(-30deg)',
          transformOrigin: 'bottom right',
          borderBottomLeftRadius: '0.5rem',
          boxShadow: '-0.2em 0.2em 0.3em -0.1em rgba(0, 0, 0, 0.15)',
        },
      } as any)}
    >
      {!quote.content && (
        <div css={css({ color: 'primary', fontSize: 5, textAlign: 'center' })}>
          <Spinner strokeWidth={2} />
        </div>
      )}
      {quote.content && (
        <Styled.p css={css({ fontSize: [2, 3, 3], color: isDark ? 'secondary' : 'primary' })}>
          {quote.content}
        </Styled.p>
      )}
      {quote.content && (
        <Styled.p css={css({ textAlign: 'right', fontSize: [1, 2, 3] })}>
          <span
            css={css({
              cursor: 'pointer',
              fontSize: [2, 3, 3],
              '&:hover .right': {
                display: 'none',
              },
              '&:hover .love-you': {
                display: 'inline-block',
              },
            })}
            onClick={refreshQuote}
          >
            <span className="right" css={css({ display: 'inline-block' })}>
              👉
            </span>
            <span className="love-you" css={css({ display: 'none' })}>
              🤟
            </span>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span css={css({ color: 'secondary', fontSize: 1 })}>{quote.author}</span>
        </Styled.p>
      )}
    </div>
  )
}
