import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'theme-ui'
import Layout from '../components/layout'
import Quote from '../components/quoteBanner'
import SEO from '../components/seo'
import BlogCategoryBlocks from '../components/blogCategoryBlocks'
import BlogTagBlocks from '../components/blogTagBlocks'

export default function () {
  return (
    <>
      <SEO title="huoyongfei's blog" />
      <Layout>
        <div
          css={css({
            pt: ['6rem', '10rem', '13rem'],
          })}
        >
          <Quote />
        </div>
        <div css={css({ mt: ['6rem', '8rem', '10rem'] })}>
          <BlogCategoryBlocks />
        </div>
        <div css={css({ mt: ['5rem', '6rem', '8rem'], pb: '6rem' })}>
          <BlogTagBlocks />
        </div>
        {/*<LodgeImage fluid={data.file.childImageSharp.fluid} alt="bg img" />*/}
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "static/bg/backImg_001.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blogs: allMdx {
      nodes {
        frontmatter {
          title
          tags
          category
          date(formatString: "YYYY-MM-DD")
        }
        id
      }
    }
  }
`
