import React, { useState, ReactNode, useEffect } from 'react'
import { Link } from 'gatsby'
import { css, Styled } from 'theme-ui'
import { BsList } from 'react-icons/bs'
import ColorModeToggleButton from './colorModeToggleButton'

import config from '../config'

const { blogCategorys, mediaQueryKeys } = config
export default function Header({ siteTitle }: { siteTitle: string }) {
  const [isShowSidebar, setIsShowSidebar] = useState(false)

  useEffect(() => {
    if (!isShowSidebar) {
      document.body.style.overflowY = 'auto'
    } else {
      document.body.style.overflowY = 'hidden'
    }
  }, [isShowSidebar])

  return (
    <div
      css={css({
        boxSizing: 'border-box',
        height: 'headerHeight',
        borderBottom: (theme: any) => `1px solid ${theme.colors.muted}`,
      } as any)}
    >
      <header
        css={css({
          position: 'relative',
          height: '100%',
          bg: 'background',
          variant: 'layouts.flex-center-between',
          maxWidth: 'containerMaxWidth',
          mx: 'auto',
          px: '1rem',
        })}
      >
        <Logo siteTitle={siteTitle} />
        <span
          css={css({
            [mediaQueryKeys.biggerThanMobile]: {
              display: 'none',
            },
          })}
        >
          <Slogan />
        </span>
        <span
          css={css({
            [mediaQueryKeys.mobile]: {
              display: 'none',
            },
          })}
        >
          <MainMenu />
        </span>
        <span
          css={css({
            position: 'absolute',
            right: '1rem',
            [mediaQueryKeys.mobile]: {
              display: 'none',
            },
          })}
        >
          <ColorModeToggleButton />
        </span>
        <SidebarIcon toggleSidebar={() => setIsShowSidebar((prev) => !prev)} />
      </header>
      <SideBar isShowSidebar={isShowSidebar} shutSidebar={() => setIsShowSidebar(false)} />
    </div>
  )
}

function SideBar(props: { isShowSidebar: boolean; shutSidebar: () => void }) {
  const { isShowSidebar, shutSidebar } = props
  return (
    <div
      css={css({
        visibility: isShowSidebar ? 'visible' : 'hidden',
        opacity: isShowSidebar ? 1 : 0,
        zIndex: 4,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgba(0,0,0,0.8)',
        transition: 'all 0.3s 0.1s',
      })}
      onClick={() => {
        shutSidebar()
      }}
    >
      <div
        css={css({
          width: '70%',
          maxWidth: '249px',
          bg: 'background',
          position: 'absolute',
          right: isShowSidebar ? '0' : '-100%',
          top: 0,
          bottom: 0,
          transition: 'right 0.3s',
        })}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <MainMenu />
        <ColorModeToggleButton
          injectedCss={{
            [mediaQueryKeys.mobile]: {
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: '1rem',
            },
          }}
        />
      </div>
    </div>
  )
}

function SidebarIcon(props: { toggleSidebar: () => void }) {
  const { toggleSidebar } = props
  return (
    <span
      css={css({
        display: 'none',
        fontSize: 3,
        fontWeight: 'bold',
        px: '1rem',
        cursor: 'pointer',
        [mediaQueryKeys.mobile]: {
          display: 'inline-block',
        },
      })}
      onClick={toggleSidebar}
    >
      <BsList />
    </span>
  )
}

function Logo(props: { siteTitle: string }) {
  const { siteTitle } = props
  return (
    <Link
      title={siteTitle}
      to="/about"
      css={css({ textDecoration: 'none', color: 'secondary', lineHeight: 1 })}
    >
      <span
        css={css({
          display: 'inline-block',
          position: 'relative',
          width: ['32px', '44px', '52px'],
          height: ['32px', '44px', '52px'],
          fontSize: [3, 4, 5],
          borderRadius: '50%',
          textAlign: 'center',
          lineHeight: ['32px', '44px', '52px'],
          ':hover .header-logo-emoji-smile': {
            display: 'none',
          },
          ':hover .header-logo-emoji-stick': {
            display: 'inherit',
          },
        })}
      >
        <span
          className="header-logo-emoji-smile"
          css={css({
            display: 'inherit',
          })}
        >
          😀
        </span>
        <span
          className="header-logo-emoji-stick"
          css={css({
            display: 'none',
          })}
        >
          😜
        </span>
      </span>
    </Link>
  )
}

function Slogan() {
  return (
    <Styled.h1 css={css({ fontSize: 2 })}>
      <span css={css({ fontSize: 4 })}>🚶‍♂️</span>Just keep moving forward.
    </Styled.h1>
  )
}

function MainMenu() {
  return (
    <ul
      css={css({
        listStyle: `none`,
        m: 0,
        [mediaQueryKeys.mobile]: {
          marginTop: '3rem',
          variant: 'layouts.flex-center-between',
          alignItems: 'start',
          flexDirection: 'column',
        },
      })}
    >
      {blogCategorys.map(({ label, value, description, path }) => (
        <ListLink key={value} to={path} description={description}>
          {label}
          {withTailSpaces(6 - label.length)}
        </ListLink>
      ))}
    </ul>
  )
}

function ListLink(props: { to: string; children: ReactNode; description: string }) {
  return (
    <li
      css={css({
        display: `inline-block`,
        marginRight: `1rem`,
        [mediaQueryKeys.mobile]: {
          marginRight: '0',
          marginBottom: '1rem',
        },
      })}
    >
      <Link
        css={css({
          color: 'primary',
          fontSize: [1, 2, 3],
          lineHeight: 1,
          cursor: 'pointer',
          display: 'inline-block',
          position: 'relative',
          transition: 'all 0.3s',
          textDecoration: 'none',
          mr: '1rem',
          '::before': {
            content: 'attr(data-describe)',
            color: 'secondary',
            display: 'none',
            width: '120%',
            fontSize: 1,
            lineHeight: 1.3,
            position: 'absolute',
            pt: '10px',
            top: 0,
            opacity: 0,
            transition: 'all 0.4s',
            zIndex: 1,
          },
          ':hover::before': {
            display: 'inline-block',
            opacity: 1,
            top: '40px',
            [mediaQueryKeys.mobile]: {
              display: 'none',
            },
          },
          '::after': {
            content: '""',
            bottom: 'calc(-100% - 2px)',
            left: 0,
            cursor: 'pointer',
            display: 'inline-block',
            height: '2px',
            position: 'absolute',
            transition: 'all 0.4s',
            width: '0',
            zIndex: 0,
            backgroundColor: 'primary',
          },
          '&:hover::after': {
            width: '100%',
            [mediaQueryKeys.mobile]: {
              width: '0',
            },
          },
        } as any)}
        to={props.to}
        data-describe={props.description}
      >
        {props.children}
      </Link>
    </li>
  )
}

function withTailSpaces(num: number) {
  if (num <= 0) return null
  return (
    <span>
      {Array.from({ length: num }, (_, i) => (
        <span key={i}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      ))}
    </span>
  )
}
