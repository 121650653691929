/** @jsx jsx */
import { jsx, css } from 'theme-ui'
import { ReactNode, Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'

export default function Layout({ children }: { children: ReactNode }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <Fragment>
      <Header siteTitle={data.site.siteMetadata.title} />
      <LayoutContentWrapper>{children}</LayoutContentWrapper>
    </Fragment>
  )
}

export function LayoutContentWrapper({ children }: { children: ReactNode }) {
  return (
    <div
      css={css({
        bg: 'secondary_background',
        minHeight: (theme: any) => `calc(100vh - ${theme.sizes.headerHeight}px)`,
      } as any)}
    >
      <div css={css({ maxWidth: 'containerMaxWidth', mx: 'auto' })}>{children}</div>
    </div>
  )
}
