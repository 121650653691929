import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { css, Styled, useColorMode } from 'theme-ui'
import config from '../config'

const { blogCategorys: bcs, mediaQueryKeys } = config

export default function BlogCategoryBlocks(props: { style?: object }) {
  const { style = {} } = props

  const {
    blogCategorys: { group: categoryGroups = [] },
    blogsNew: { group: blogGroups = [] },
  } = useStaticQuery(
    graphql`
      query {
        blogCategorys: allMdx {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
        blogsNew: allMdx(sort: { order: DESC, fields: frontmatter___date }) {
          group(field: frontmatter___category, limit: 10) {
            nodes {
              fields {
                slug
              }
              frontmatter {
                title
                date
              }
              id
            }
            fieldValue
          }
        }
      }
    `
  )

  const [currentColorMode] = useColorMode()
  const isDark = currentColorMode === 'dark'

  return (
    <div
      style={style}
      css={css({
        display: 'flex',
        justifyContent: ['center', 'center', 'space-between'],
        flexWrap: 'wrap',
        px: '0.5rem',
      })}
    >
      {categoryGroups.map(({ fieldValue = '', totalCount = 0 }) => {
        const blogGroup = blogGroups.find(({ fieldValue: fv = '' }) => fv === fieldValue) || {
          nodes: [],
        }

        return (
          <CategoryBlock
            key={fieldValue}
            totalCount={totalCount}
            category={fieldValue}
            categoryArr={blogGroup.nodes}
            isDark={isDark}
          />
        )
      })}
    </div>
  )
}

function CategoryBlock(props: {
  category: string
  categoryArr: Array<{
    id: string
    frontmatter: { title: string; date: Date }
    fields: { slug: string }
  }>
  totalCount: number
  isDark: boolean
}) {
  const { category, categoryArr, totalCount, isDark } = props

  const bcsInfo = bcs.find(({ value }) => value === category)
  if (!bcsInfo) {
    console.error(`can not find category: ${category}`)
    return null
  }
  const { label, path, description } = bcsInfo

  return (
    <div
      css={css({
        bg: 'background',
        borderRadius: '1rem',
        boxShadow: '0.1em 0.1em 0.2em 0.2em rgba(0, 0, 0, 0.05)',
        transition: 'all 0.3s',
        maxWidth: ['100%', '100%', '48%'],
        border: isDark ? (theme: any) => `1px solid ${theme.colors.primary}` : 'none',
        mb: '2rem',
        [mediaQueryKeys.biggerThanMobile]: {
          ':hover': {
            transform: 'scale(1.02)',
            boxShadow: '0.1em 0.1em 0.5em 0.5em rgba(0, 0, 0, 0.05)',
          },
        },
      } as any)}
    >
      <div
        css={css({
          px: '2rem',
          py: '0.5rem',
          borderBottom: (theme: any) => `1px solid ${theme.colors.muted}`,
        } as any)}
      >
        <Styled.h1
          css={css({
            borderLeft: (theme: any) => `0.5rem solid ${theme.colors.primary}`,
            fontSize: 4,
            pl: '0.5rem',
          } as any)}
        >
          <Link
            css={css({
              color: 'text',
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            })}
            to={path}
          >
            {label}
          </Link>
        </Styled.h1>
        <p css={css({ lineHeight: 1.2, mb: 0 })}>
          <Link
            css={css({
              color: 'text',
              textDecoration: 'none',
              fontSize: 1,
              lineHeight: 1,
              ':hover': {
                textDecoration: 'underline',
              },
            })}
            to={path}
          >
            {description}
          </Link>
        </p>
      </div>
      <div
        css={css({
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row', 'row'],
          padding: '1rem 2rem 2rem',
        })}
      >
        <div
          css={css({
            px: '1rem',
            py: '1rem',
            border: (theme: any) => `1px solid ${theme.colors.muted}`,
            borderRadius: '0.5rem',
            variant: 'layouts.flex-center-between',
            display: ['none', 'flex', 'flex'],
            minHeight: '100%',
            lineHeight: 1,
            mr: '1rem',
          } as any)}
        >
          <span css={css({ fontSize: [2, 2, 3] })}>
            <span>共：</span>
            <br />
            <span>篇</span>
          </span>
          <Link
            css={css({
              fontSize: 6,
              color: 'primary',
              textDecoration: 'none',
              [mediaQueryKeys.biggerThanMobile]: {
                ':hover': {
                  textDecoration: 'underline',
                },
              },
            })}
            to={path}
          >
            {totalCount}
          </Link>
        </div>
        <div
          css={css({
            flex: 1,
          })}
        >
          <div
            css={css({
              fontWeight: 500,
              borderLeft: (theme: any) => `0.2rem solid ${theme.colors.primary}`,
              pl: '0.5rem',
            } as any)}
          >
            最新更新：
          </div>
          <div>
            {categoryArr.map(({ id, frontmatter: { title, date }, fields: { slug } }) => {
              return <BlogItem key={id} title={title} date={date} path={slug} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function BlogItem(props: { title: string; date: Date; path: string }) {
  const { title, date, path } = props

  const formateDate = (date: Date) => {
    return Date.prototype.toLocaleDateString && new Date(date).toLocaleDateString()
  }

  return (
    <div
      css={css({
        lineHeight: 1.8,
        variant: 'layouts.flex-center-between',
        borderBottom: (theme: any) => `1px solid ${theme.colors.muted}`,
      } as any)}
    >
      <Link
        css={css({
          color: 'primary',
          textDecoration: 'none',
          flex: 1,
          fontSize: 1,
          ':hover': {
            textDecoration: 'underline',
          },
        })}
        to={`${path}`}
      >
        <span>{title}</span>
      </Link>
      <span css={css({ fontSize: 1 })}>{formateDate(date)}</span>
    </div>
  )
}
